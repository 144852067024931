<template>
  <div class="cart-detail">
    <!--<p class="m-bottom-md">
      <a-tag class="font-sm" color="black">统一定价</a-tag>
      <span class="font-sm">本首歌曲为统一定价，请选择以下购买用途和授权范围，以便为您确认购买价格。</span>
    </p>-->

    <a-form :form="form" hideRequiredMark :label-col="{ span: 24}" :wrapper-col="{ span: 24 }">
      <template v-for="(item, index) in AllMenuList">
        <a-form-item
          v-if="AllOptions[index] && AllOptions[index].length > 0"
          :label="item.name"
          :key="item.key"
        >
          <a-radio-group
            button-style="solid"
            v-decorator="[item.key, { rules: [$root.$validate.checkRequired]}]"
          >
            <template v-for="cItem in AllOptions[index]">
              <a-radio-button
                v-if="item.key === 'use_mode' || (item.key !== 'use_mode' && cItem.is_enabled === $val.CommonCheck.correct)"
                class="pos-relative"
                :value="cItem.type"
                :key="cItem.type"
              >
                <template v-if="item.key === 'auth_scene'">
                  <span>{{cItem.type | toAuthScene}}</span>
                  <img src="@/assets/images/web/cart/sel.svg" v-if="AllMenuList[index].selIdx==cItem.type"/>
                  <img src="@/assets/images/web/cart/no-sel.png" v-else />
                </template>
                <template v-else-if="item.key === 'use_mode'">
                  <a-tooltip :title="cItem.desc">
                    <div>
                      {{cItem.type}}
                      <img src="@/assets/images/web/cart/sel.svg" v-if="AllMenuList[index].selIdx==cItem.type"/>
                      <img src="@/assets/images/web/cart/no-sel.png" v-else />
                    </div>
                  </a-tooltip>
                </template>
                <template v-else-if="item.key === 'auth_time'">
                  {{cItem.type | toAuthPeriod}}
                  <img src="@/assets/images/web/cart/sel.svg" v-if="AllMenuList[index].selIdx==cItem.type"/>
                  <img src="@/assets/images/web/cart/no-sel.png" v-else />
                </template>
                <template v-else-if="item.key === 'auth_area'">
                  {{cItem.type | toArea}}
                  <img src="@/assets/images/web/cart/sel.svg" v-if="AllMenuList[index].selIdx==cItem.type"/>
                  <img src="@/assets/images/web/cart/no-sel.png" v-else />
                </template>
                <span v-if="isSongList && cItem.discount" class="discount">折</span>
              </a-radio-button>
            </template>
          </a-radio-group>
        </a-form-item>
      </template>

      <a-form-item v-if="isSongList" style="display: none;">
        <a-input v-decorator="['discount_id', {initialValue: ''}]"></a-input>
      </a-form-item>

      <!--<p
        class="lh-1-5"
      >*个人用途授权的音乐作品严禁商用或作为beats进行改编（如填词改编等其他二度创作），若超出授权范围使用，您可能面临平台及版权方的追责，对此产生的全部责任由您本人承担。</p>-->
    </a-form>

    <transition name="fade-out">
      <a-spin v-if="loading" class="spin-back pos-abs-all-area w-100 h-100" :loading="loading"></a-spin>
    </transition>
  </div>
</template>

<script>
  import {Spin, Tag, Tooltip} from "ant-design-vue";
  import {dialogMixins} from '@/mixins/index';
  import {mul, div} from '@/utils/from-common';

  // 如果要改变顺序同时需要修改getDataTree里边树的加载层级
  const AllMenuList = [
    {
      name: '授权场景',
      key: 'auth_scene',
      type: 'type',
      selIdx: 0,
    },
    {
      name: '使用方式',
      key: 'use_mode',
      type: 'name',
      selIdx: 0,
    },
    {
      name: '授权地域',
      key: 'auth_area',
      type: 'type',
      selIdx: 0,
    },
    {
      name: '授权期限',
      key: 'auth_time',
      type: 'type',
      selIdx: 0,
    }
  ]

  // 所有需要判断得字段，用来处理是否添加折扣标识 
  const AllNeedCheckKey = AllMenuList.map(item => item.key);

  // 获取当前点击key值下标
  const getCurKeyIndex = (key) => AllNeedCheckKey.indexOf(key);

  const getKeyStr = (...arg) => ([...arg].join('_'))

  const getCurKeyArr = (values, index) => AllNeedCheckKey.slice(0, index).reduce((p, n) => {
    values[n] && p.push(values[n])
    return p;
  }, [])

  // 分割符
  const splitCode = '_**_';

  export default {
    data() {
      return {
        confirmLoading: false,

        AllMenuList,

        AllOptions: [],


        // AuthOptions: [],
        SceneOptions: [],
        UseModeOptions: [],
        AuthAreaRenderOptions: [],
        AuthTimeRenderOptions: [],

        loading: false,
        splitCode,
        allPrice: '',
        allFieldFinised: false, // 所有选项都选中

        submitingCart: false,

        musicInfo: {},
        discount_id: '',
        // discountList: [],
      }
    },

    props: {
      data: Object,
      isEdit: Boolean,
      show: Boolean,
    },

    computed: {
      isSongList() {
        let data = this.data;

        return !!(data && data.isSongList && data.sid);
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values, allValues) => {
          let curKey = Object.keys(values)[0];
          let handleIndex = getCurKeyIndex(curKey);
          if(curKey == 'auth_scene'){
            this.AllMenuList[0].selIdx = values.auth_scene;
            this.AllMenuList[1].selIdx = 0;
            this.AllMenuList[2].selIdx = 0;
            this.AllMenuList[3].selIdx = 0;

          }
          if(curKey == 'use_mode'){
            this.AllMenuList[1].selIdx = values.use_mode;
            this.AllMenuList[2].selIdx = 0;
            this.AllMenuList[3].selIdx = 0;
          }
          if(curKey == 'auth_area'){
            this.AllMenuList[2].selIdx = values.auth_area;
            this.AllMenuList[3].selIdx = 0;
          }
          if(curKey == 'auth_time'){
            this.AllMenuList[3].selIdx = values.auth_time;
          }

          if (~handleIndex) {
            handleIndex++;

            let lastArr = AllNeedCheckKey.slice(handleIndex);

            // 重置选中项
            this.form.resetFields(lastArr);
            this.AllOptions.splice(handleIndex);
            allValues = AllNeedCheckKey.slice(0, handleIndex).reduce((p, n) => ({...p, [n]: allValues[n]}), {})

            this.$nextTick(() => {
              let curKeyStr = getKeyStr(...getCurKeyArr(allValues, handleIndex));
              let lastOptions = this.allDataMap[curKeyStr];

              lastOptions && this.AllOptions.push(lastOptions);

              let isFinished = AllNeedCheckKey.every(item => allValues[item]);

              this.$emit('changeFinished', isFinished)

              if (values.use_mode) {
                let useModeIndex = AllNeedCheckKey.indexOf('use_mode')
                this.getBasePrice(this.AllOptions[useModeIndex], values.use_mode);
              }

              if (isFinished) {
                let allRatio = this.getRatio(this.AllOptions, allValues);

                if (this.isSongList) {
                  let curDisInfo = this.getCurDiscountInfo(this.discountList, allValues);
                  let discount_id = curDisInfo.discount_id || '';
                  this.discount_id = discount_id;

                  this.$emit('changeDiscount', curDisInfo);

                  this.form.setFieldsValue({discount_id});
                }

                this.allPrice = this.calcPrice(allRatio);

                this.$emit('changePrice', this.allPrice);
              }
            })
          }
        }
      });
    },

    created() {
      this.basePrice = 0; // 基础价格
      this.allPrice = 0;
      this.allDataMap = {};
      this.discountList = [];
    },

    watch: {
      data: {
        handler(newVal) {
          if (newVal) {
            let proArr = [], music_id = newVal.music_id, sid = newVal.sid;

            if (music_id) {
              proArr.push(this.getPriceInfo({music_id}))
              newVal.isSongList && sid && proArr.push(this.getDiscountList({playlist_id: sid}))
            }

            this.loading = true;

            Promise.all(proArr).then(([priceInfo, discountList]) => {
              this.loading = false;

              this.allDataMap = this.getAllDataMap(priceInfo, discountList);

              this.AllOptions.push(this.allDataMap.scene || []);
              this.discountList = discountList;

              this.isEdit && this.initDefault(newVal);
            }).catch(() => {
              this.loading = false;
            })
          }
        },
        immediate: true
      },
      show: {
        handler(newVal) {
          if (newVal) {
            this.AllOptions.splice(0);
            this.allPrice = '0';
            this.discount_id = '';
            this.allFieldFinised = false;

            this.$nextTick(() => {
              this.form.resetFields();
              this.$emit('changeFinished', this.allFieldFinised)
              this.$emit('changePrice', this.allPrice);
              this.$emit('changeDiscount', {});
            })
          } else {
            this.$store.commit('changeCurrentMusicDetail', {}) 
            //init checked button
            this.AllMenuList[0].selIdx = 0;
            this.AllMenuList[1].selIdx = 0;
            this.AllMenuList[2].selIdx = 0;
            this.AllMenuList[3].selIdx = 0;
          }
        }
      }
    },

    components: {
      ASpin: Spin,
      ATag: Tag,
      ATooltip: Tooltip
    },

    mixins: [dialogMixins],

    methods: {

      getDataTree(scene) {
        scene = scene.map(item => {
          let {auth: {time = [], area = []}, use_mode = []} = item;

          use_mode = use_mode.map(({name, ...item}) => ({type: name, ...item}));

          let timeChild = {key: 'auth_time', list: time};
          let areaAndTime = {key: 'auth_area', list: area.map(item => ({...item, child: timeChild}))}

          return {...item, child: {key: 'use_mode', list: use_mode.map(item => ({...item, child: areaAndTime}))}}
        })

        scene = {key: 'auth_scene', list: scene};

        return scene;
      },

      getAllDataMap(priceInfo, dList) {
        let scene = priceInfo.scene || [];
        let {handleDiscountData} = this;

        scene = this.getDataTree(scene);

        let extraDir = one(scene, dList);
        let sceneDir = handleDiscountData(scene.list, dList, {}, 'type', scene.key);

        return Object.assign({scene: sceneDir}, extraDir);

        function one(obj, dList, keyArr = [], checkDir = {}) {
          let {key, list} = obj;

          return list.reduce((prev, next) => {
            let {type, child = {}} = next;

            if (child && child.key) {
              let {key: cKey, list: cList} = child;

              checkDir[key] = type;
              keyArr.push(type);

              prev[keyArr.join('_')] = handleDiscountData(cList, dList, checkDir, 'type', cKey);

              prev = Object.assign(prev, one(child, dList, keyArr, checkDir) || {});

              keyArr.pop();
              delete checkDir[key];
            }

            return prev;
          }, {})
        }
      },

      /**
       * checkKeyArr 需要判断得key集合
       */
      handleDiscountData(list, discountList = [], checkTargetDir = {}, originKey, targetKey) {
        if (this.isSongList && discountList.length > 0) {
          return list.map(item => {
            checkTargetDir = Object.assign({}, checkTargetDir, {[targetKey]: item[originKey] || ''})

            return this.handleDiscountItem(item, discountList, checkTargetDir)
          })
        }

        return list;
      },

      handleDiscountItem(scope, discountList, checkTargetDir = {}) {
        return Object.assign({}, scope, {
          discount: discountList.some(item => Object.entries(checkTargetDir).every(([key, value]) => item[key] === value))
        })
      },

      getPriceInfo(params) {
        return new Promise((resolve, reject) => {
          this.$axios.Music_piceInfo(params).then(res => {
            let data = res.data;

            if (data && data.code == 0) {
              data = data.data ? data.data : {};

              resolve(data);
            } else {
              reject(data);
            }
          }, reject)
        })
      },

      getDiscountList(params) {
        return new Promise((resolve, reject) => {
          this.$axios.Songlist_DiscountList(params).then(res => {
            let data = res.data;

            if (data && data.code == 0) {
              data = data.data ? data.data.list : [];

              resolve(data);
            } else {
              reject(data);
            }
          }, reject)
        })
      },

      async initDefault(scope = {}) {
        let {auth_scene, use_mode, auth_time, auth_area, discount_id} = scope;
        let params = {auth_scene};
        discount_id && (params.discount_id = discount_id);

        await this.$nextTick()
        this.form.setFieldsValue(params);

        await this.$nextTick()
        this.form.setFieldsValue({use_mode});

        await this.$nextTick()
        this.form.setFieldsValue({auth_area});

        await this.$nextTick()
        this.form.setFieldsValue({auth_time});
      },

      getBasePrice(list = [], value) {
        list.some(item => {
          if (item.type === value) {
            this.basePrice = item.price;

            return true;
          }
        })
      },

      getCurDiscountInfo(dList, values) {
        let obj = {};

        dList.some(item => {
          if (Object.entries(values).every(([key, value]) => item[key] === value)) {
            obj = item;
            return true;
          }
        })

        return obj;
      },

      getDiscountRatio(dList, values) {
        let ratio = '';

        dList.some(item => {
          if (Object.entries(values).every(([key, value]) => item[key] === value)) {
            ratio = item.ratio;
            return true;
          }
        })

        return ratio;
      },

      getRatio(list, values) {
        let ratioArr = [];
        let ratios = ['auth_time', 'auth_area'];

        ratios.forEach(item => {
          let index = AllNeedCheckKey.indexOf(item);

          let final = list[index] || [];

          final.some(cItem => {
            if (cItem.type === values[item]) {
              ratioArr.push(cItem.ratio);
              return true;
            }
          })
        })

        return ratioArr;
      },

      calcPrice(allRatioArr = []) {
        let percent = 100;
        let allPrice = 0;

        if (allRatioArr.length > 0) {
          let allRatio = this.calcRatio(allRatioArr, percent);

          if (allRatio && this.basePrice) {
            allPrice = mul(allRatio, this.basePrice);
          }
        }

        return allPrice;
      },

      calcRatio(ratioArr) {
        let allRatio = 1; // 基础 比率

        if (ratioArr && ratioArr.length > 0) {
          return ratioArr.reduce((a, b) => mul(a, div(b, 100)), allRatio)
        }

        return allRatio;
      },
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-radio-button-wrapper {
      padding: 0px;
      padding-left:15px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 2px;
      border: 1px solid #999999;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      img{
        margin-top:13px;
      }
    }

    .ant-form-item-label {
      font-weight: initial;
      > label {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-radius: 2px;
      border: 1px solid #FF7B13;
      background:#FFFFFF;
      color: rgba(0, 0, 0, 0.85);
      box-shadow: none;
    }
  }

  .discount {
    position: absolute;
    top: -10px;
    right: -6px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    background-color: #2e3557;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
</style>
